import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import Card1 from 'site/cards/Card1';


export default (customParams) => (componentProps) => {
  const props = { ...componentProps, ...customParams };
  const {
    bebopApi,
    fromList,
    consoleError,
  } = props;

  const params = fromList ?
    { list: 'news', sort: 'list' } :
    { topic_type: 'news' };

  return bebopApi.getTopics({
    limit: 9,
    fields: filterRequiredParams([Card1], 'fields'),
    ...params,
  })
    .then(denormalizeData)
    .catch(consoleError('news list', []));
};
