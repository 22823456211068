import ContentBackground from 'core/components/ContentBackground';
import Shapka, { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Desktop } from 'core/components/breakpoint';

import withGlobalTopicContext from 'core/components/GlobalTopicProvider/withGlobalTopicContext';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import Scooter from '../Scooter';

import { Billboard } from '../Ads/desktop';

import styles from './index.styl';


function SiteLayout({ children, content }) {
  const {
    is_premium: isPremiumTopic,
  } = content?.attributes || {};

  return (
    <StickyContainer>
      <ContentBackground>
        <Layout>
          <Header>
            <Shapka isSecondShapkaForPremium={isPremiumTopic} fullwidth={isPremiumTopic} />
          </Header>
          <Content>
            {!isPremiumTopic && (
              <Desktop>
                <Billboard />
              </Desktop>
            )}
            <div className={styles.content}>
              {children}
            </div>
          </Content>
          <Footer>
            <Scooter />
          </Footer>
        </Layout>
      </ContentBackground>
    </StickyContainer>
  );
}

SiteLayout.propTypes = {
  content: modelPropTypes(topicAttributes),
};

export default withGlobalTopicContext(SiteLayout);
