import PropTypes from 'prop-types';
import { Fragment } from 'react';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import resolve from 'core/resolver/resolve';

import { filterRequiredParams } from 'core/utils/api';

import Rubric from 'core/components/Rubric';
import { isGameCompare } from 'core/components/GameCompare/utils';
import withPageHocs from 'core/components/withPageHocs';

import { SideIndent } from 'core/components/Wrappers';

import FooterAds from 'site/components/Ads/FooterAds';
import PageLayout from 'site/components/PageLayout';
import Headline from 'site/components/Headline';
import News from 'site/components/News';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import newsFetcher from 'site/fetchers/news';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import CompareCard from 'site/cards/CompareCard';

import SectionContent from './Content';
import SectionNewsContent from './News';
import GameCompareRubric from './GameCompareRubric';


function RubricPage({ rawRubric, rawTopics, news, match, location }) {
  const {
    params: {
      level_1: level1,
    },
  } = match;

  const isNews = level1 === 'news';

  return (
    <Fragment>
      <PageLayout>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          titleComponent={Headline}
          limit={RUBRICS_PAGE_LIMIT}
        >
          {props => {
            if (isNews) {
              return <SectionNewsContent {...props} />;
            }

            const isGameComparePage = isGameCompare(location.pathname);

            return (
              <Fragment>
                <News topics={news} />
                {isGameComparePage && <GameCompareRubric {...props} />}

                {!isGameComparePage && <SectionContent {...props} cardsForRubric />}
              </Fragment>
            );
          }}
        </Rubric>
      </PageLayout>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

RubricPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string,
      level_2: PropTypes.string,
    }).isRequired,
  }).isRequired,
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
  news: PropTypes.array,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),

  rawTopics: (props) => {
    const {
      match: {
        params: {
          level_1: level1,
        },
      },
    } = props;

    const isNews = level1 === 'news';
    return rawTopicsFetcher({
      limit: RUBRICS_PAGE_LIMIT,
      include: filterRequiredParams([Card2, Card3, Card4, CompareCard], 'include'),
      fields: filterRequiredParams([Card2, Card3, Card4, CompareCard], 'fields'),
      topic_type: isNews ? 'news' : '-news',
    })(props);
  },

  news: props => {
    const { location, match } = props;
    const {
      params: {
        level_2: level2,
      },
    } = match;

    const newsNeeded = level2 !== 'ya-tak-zhivu' && !isGameCompare(location.pathname);

    if (!newsNeeded) return null;

    return newsFetcher()(props);
  },
});

export default withPageHocs(dataProvider)(RubricPage);
