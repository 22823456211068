export default {
  1: { size: 's', withRubric: true },
  2: { size: 'm', withDate: true, withRubric: true },
  3: { size: 'partner', rowReverse: true, hideAltHeadline: true },
  4: { size: 'm', withDate: true },
  5: { size: 'partner', withDate: true, withRubric: true, hideImage: true, hideAltHeadline: true },
  6: { size: 'm', withDate: true, withRubric: true, hideImage: true },
  7: { size: 'm', withDate: true, withRubric: true, withBackground: true },
  8: { size: 'l' },
};
