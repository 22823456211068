export default {
  s: {
    headlineSize: '700 14px/18px',
    altHeadlineSize: '14px/18px',
    width: 60,
    height: 60,
  },
  m: {
    headlineSize: '700 18px/22px',
    altHeadlineSize: '14px/18px',
    width: 80,
    height: 80,
  },
  l: {
    headlineSize: '700 18px/22px',
    altHeadlineSize: '14px/18px',
    width: 100,
    height: 100,
  },
  partner: {
    headlineSize: '16px/20px',
    altHeadlineSize: '14px/18px',
    width: 80,
    height: 80,
  },
};
