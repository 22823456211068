import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';


function Headline({ children, theme, isMobile }) {
  return (
    <h1 className='headline' data-qa='page-headline'>
      <style jsx>{`
        .headline
          font ${theme.texts.headline.font}
          color ${theme.texts.headline.color}
      `}
      </style>
      <style jsx>{`
        .headline
          margin 0 0 ${isMobile ? 10 : 30}px 0
          padding 0
      `}
      </style>
      {children}
    </h1>
  );
}

Headline.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const HeadlineWithHOCs = withBreakpoint(withTheme(Headline));
HeadlineWithHOCs.displayName = 'Headline';

export default HeadlineWithHOCs;

export { Headline as StorybookComponent };
