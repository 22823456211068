export default {
  mobile: {
    headlineSize: '28px/36px',
    headlineSizeForSmallScreen: '22px/26px',
    altHeadlineSize: '20px/26px',
    width: 600,
    height: 600,
    padding: '25px 20px 20px',
  },
  desktop: {
    headlineSize: '30px/36px',
    headlineSizeForSmallScreen: '25px/28px',
    altHeadlineSize: '20px/32px',
    width: 834,
    height: 500,
    padding: '50px 40px 32px 72px',
  },
};
