// indents
export const SIDE_INDENT = 58;
export const SIDE_INDENT_INNER = 30;
export const SIDE_INDENT_MOBILE = 20;
export const VERTICAL_INDENT = 30;
export const VERTICAL_INDENT_ADV_TOP = 50;
export const VERTICAL_INDENT_ADV_BOTTOM = VERTICAL_INDENT_ADV_TOP - VERTICAL_INDENT;
export const VERTICAL_INDENT_MOBILE = 20;
export const TOPIC_VIKONTS_INDENT = 26;

// limits
export const RUBRICS_PAGE_LIMIT = 16;

// sizes
export const LAYOUT_MAX_WIDTH = 1280;
export const LAYOUT_MAX_WIDTH_MOBILE = 600;
export const CONTENT_AREA = 834;
export const RIGHT_COLUMN_WIDTH = 300;

export const getUtm = (breakpoint) => (`?utm_source=read_more&utm_medium=${breakpoint}&utm_campaign=Research_read_more_traffic`);

export const DEFAULT_CARD_COVER = {
  rel_url: require('site/cards/cover-default.png'),
  width: 1200,
  height: 645,
};

export const DEFAULT_CARD_COVER_SQUARE = {
  rel_url: require('site/cards/cover-default-square.png'),
  width: 750,
  height: 750,
};
