import ThemeSwitch from 'core/components/ThemeSwitch';
import Scooter from 'core/components/Scooter';
import Link from 'core/components/Link';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

const scope = resolveScopedStyles(
  <scope>
    <style jsx>{`
      .menu-list-custom
        :global(.desktop) &
          justify-content space-evenly
    `}</style>
  </scope>
);

export default function SiteScooter() {
  return (
    <ThemeSwitch name='dark'>
      <Scooter
        services={[
          { link: '/about#advertising', content: 'Размещение рекламы' },
          { link: '/about', content: 'Редакция' },
          { link: '/exports/rss.xml', content: 'RSS' },
          { link: '/legal', content: 'Правовая информация' },
          { link: '/legal/terms-of-use', content: 'Условия использования' },
          { link: '/legal/privacy', content: 'Политика конфиденциальности' },
        ]}
        commercial={[
          'Все права защищены. Полное или частичное копирование материалов Сайта в коммерческих целях разрешено только с письменного разрешения владельца Сайта. В случае обнаружения нарушений, виновные лица могут быть привлечены к ответственности в соответствии с действующим законодательством Российской Федерации.',
          {
            body: <Link type='tertiary' to='/rules-recommenders'>На информационном ресурсе применяются рекомендательные технологии в соответствии с Правилами</Link>,
          },
        ]}
        contacts={{
          site: 'Moslenta',
          age: 18,
          phone: {
            caption: 'Телефон редакции:',
            body: '+7 495 785-17-00',
          },
        }}
        menuListClassName={scope.wrapClassNames('menu-list-custom')}
      />
      <scope.styles />
    </ThemeSwitch>
  );
}
