import PropTypes from 'prop-types';

import H4 from 'core/components/H4';
import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { TOPIC_VIKONTS_INDENT } from 'site/constants';
import mapTypes from './types';

function FeedHeader({ theme, type, children, marginBottom }) {
  const {
    themeColor,
  } = mapTypes[type];

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        h4.feed-header
          color ${theme.colors[themeColor]}
          margin-bottom ${marginBottom}px
      `}</style>
    </scope>
  );

  return (
    <H4 className={scoped.wrapClassNames('feed-header')}>
      {children}
      <scoped.styles />
    </H4>
  );
}

FeedHeader.propTypes = {
  /**
   * Тип заголовка, отображаемого в соответствии
   * с указанной темой
   */
  type: PropTypes.oneOf([1, 2]),
  /** @ignore */
  theme: PropTypes.object,
  /** Отступ до контента */
  marginBottom: PropTypes.number,
};

FeedHeader.defaultProps = {
  type: 1,
  marginBottom: TOPIC_VIKONTS_INDENT,
};

const FeedHeaderWithHOCs = withTheme(FeedHeader);
FeedHeaderWithHOCs.displayName = 'FeedHeader';

export default FeedHeaderWithHOCs;

export { FeedHeader as StorybookComponent };
