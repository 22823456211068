import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import Topic from 'core/components/Topic';

import topicProvider from 'core/resolver/data/topic';
import { Indent, SideIndent, TopicContentMaxWidth } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import TopicWrapper from 'site/pages/topic/TopicWrapper';
import TopicHeader from 'site/pages/topic/TopicHeader';

import FooterAds from 'site/components/Ads/FooterAds';
import { Fragment } from 'react';


function CommentsPage({ content }) {
  return (
    <Fragment>
      <Topic content={content}>
        {topic => {
          const {
            id,
            attributes: {
              link,
              is_comments_disabled: isCommentsDisabled,
              is_premium: isPremium,
            },
          } = topic;

          return (
            <TopicWrapper content={topic} comments>
              {!isPremium && (
                <Indent bottom={20}>
                  <TopicHeader
                    hideCover
                    content={topic}
                    comments
                  />
                </Indent>
              )}
              <TopicContentMaxWidth>
                <Indent bottom={20}>
                  <Link to={link} type='secondary'>
                    <Button>Вернуться к статье</Button>
                  </Link>
                </Indent>
                <Comments
                  adv
                  advInterval={4}
                  xid={id}
                  isCommentsDisabled={isCommentsDisabled}
                />
              </TopicContentMaxWidth>
            </TopicWrapper>
          );
        }}
      </Topic>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

CommentsPage.propTypes = {
  content: PropTypes.object,
};

export default withPageHocs(topicProvider)(CommentsPage);
