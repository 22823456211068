import PropTypes from 'prop-types';
import cx from 'classnames';

import skip from 'core/resolver/skip';

import resolveRelationships from 'core/utils/relationships';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import withTheme from 'core/components/theme';

import styles from './index.styl';

import { cardDateFormat } from 'site/utils';
import { DEFAULT_CARD_COVER } from 'site/constants';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
});

function Card6(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRoot,
    },
  } = relationships(content);

  return (
    <div className={cx(
      'card6',
      !originalCover && styles._defaultCover,
    )}
    >
      <style jsx>{`
        .${styles.content}
          border-bottom 2px solid ${theme.colors.divider}

        .${styles.headline}
          font 700 26px/30px ${theme.fonts.nuance}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}

        .altHeadline
          font 18px/24px ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.overlay}
          background ${theme.colors.primary}
          transition opacity ${theme.animations.hover}

        .${styles.rubricText}
          font 700 12px/1.2 ${theme.fonts.nuance}
          color ${theme.colors.content}
          background ${theme.colors.hint}
          transition background ${theme.animations.hover}

        .date
          font 14px/18px ${theme.fonts.nuance}
          color ${theme.colors.hint}

          &:after
            background ${theme.colors.hint}

        .card6
          &:hover
            .${styles.headline}
              color ${theme.colors.active1}

            .${styles.rubricText}
              background ${theme.colors.active1}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.image} data-qa='card-image'>
          <Image
            src={originalCover || DEFAULT_CARD_COVER}
            previewSrc={previewCover}
            maxWidth={300}
            maxHeight={180}
          />
          <div className={styles.overlay} />
          <div className={styles.rubric} data-qa='card-rubric'>
            <div className={styles.rubricText}>{rubricRoot}</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.meta}>
            <div className='date'>
              {cardDateFormat(publishedAt, { forceDisableYear: true })}
            </div>
          </div>
          <div className={styles.headline} data-qa='card-headline'>{listHeadline || headline}</div>
          <div className='altHeadline'>{altHeadline}</div>
        </div>
      </Link>
    </div>
  );
}

Card6.propTypes = {
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = skip(withTheme(Card6));

Card.displayName = 'Card6';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card6 as StorybookComponent };
