import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withTheme from 'core/components/theme';

import { Desktop } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import TopicFooterCore from 'core/components/TopicFooter';
import { Indent, TopicContentMaxWidth } from 'core/components/Wrappers';

import Sponsored from 'site/components/Ads/Sponsored';
import { SuperFooter } from 'site/components/Ads/desktop';


function TopicFooter(props) {
  const {
    hideSuperFooter,
    content,
    theme: {
      controls: {
        topicFooter: {
          block: {
            spacing,
          },
        },
      },
    },
  } = props;

  return (
    <Fragment>
      <Indent bottom={spacing}>
        <TopicFooterCore
          content={content}
          hrefBuilder={slug => `/label/${slug}`}
        />
      </Indent>
      <TopicContentMaxWidth>
        <AdWrapper bottom={spacing}>
          <Sponsored />
        </AdWrapper>
      </TopicContentMaxWidth>
      <Desktop>
        {!hideSuperFooter && (
          <Indent top={spacing}>
            <AdWrapper bottom={spacing}>
              <SuperFooter />
            </AdWrapper>
          </Indent>
        )}
      </Desktop>
    </Fragment>
  );
}

TopicFooter.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
  hideSuperFooter: PropTypes.bool,
};

export default withTheme(TopicFooter);
