import bindProps from 'core/components/bindProps';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import CardMain from 'site/cards/CardMain';


const Card2Type2 = bindProps({ type: 2 })(Card2);
const Card2Type3 = bindProps({ type: 3 })(Card2);
const Card3Type2 = bindProps({ type: 2 })(Card3);
const Card3Type4 = bindProps({ type: 4 })(Card3);

const CardMainType1 = bindProps({ type: 1 })(CardMain);
const CardMainType2 = bindProps({ type: 2 })(CardMain);
const CardMainType3 = bindProps({ type: 3 })(CardMain);
const CardMainType4 = bindProps({ type: 4 })(CardMain);

export default {
  desktop: {
    sm: {
      Card: CardMainType4,
      rubric: {
        Card: CardMainType1,
      },
    },
    md: {
      Card: CardMainType3,
      rubric: {
        Card: CardMainType2,
      },
    },
  },
  mobile: {
    sm: {
      Card: Card3Type2,
      rubric: {
        Card: Card3Type4,
      },
    },
    md: {
      Card: Card2Type3,
      rubric: {
        Card: Card2Type2,
      },
    },
  },
};
