import PropTypes from 'prop-types';

import color from 'core/libs/color';
import resolveRelationships from 'core/utils/relationships';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';

import { cardDateFormat } from 'site/utils';
import { DEFAULT_CARD_COVER, DEFAULT_CARD_COVER_SQUARE } from 'site/constants';

import mapTypes from './types';
import mapSizes from './sizes';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
});


function Card4(props) {
  const {
    content,
    theme,
    type,
    isMobile,
    highPriorityImage,
  } = props;

  const {
    size,
    hideRubric,
  } = mapTypes[type] || {};

  const {
    headlineSize,
    headlineSizeForSmallScreen,
    altHeadlineSize,
    width: imageWidth,
    height: imageHeight,
    padding: contentPadding,
  } = mapSizes[size] || {};

  if (!content) return null;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRoot,
    },
  } = relationships(content);

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    published_at: publishedAt,
  } = content.attributes;

  return (
    <div data-qa='card4' className='card4'>
      <style jsx>{`
        .${styles.content}
        .${styles.rubricText}
          padding ${contentPadding}

        .${styles.headline}
          font 800 ${headlineSize} ${theme.fonts.display}
          color ${theme.colors.content}

          @media (max-width: 1024px)
            font 800 ${headlineSizeForSmallScreen} ${theme.fonts.display}

        .${styles.altHeadline}
          font ${altHeadlineSize} ${theme.fonts.nuance}
          color ${theme.colors.content}

        .date
          font 14px/32px ${theme.fonts.nuance}
          color ${theme.colors.content}

        .${styles.rubric}
          font 700 18px/1.2 ${theme.fonts.nuance}
          color ${theme.colors.content}

        .${styles.overlay}
          transition opacity ${theme.animations.hover}
          background ${theme.colors.primary}

          :global(.mobile) &
            background linear-gradient(180deg,
              ${color(theme.colors.primary).alpha(0).string()} 21%,
              ${theme.colors.primary} 72%,
              ${theme.colors.primary} 100%
            )

        .${styles.image}
          &:after
            background ${theme.colors.primary}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.image}>
          {!hideRubric && (
            <div className={styles.rubric} data-qa='card4-rubric'>
              <div className={styles.rubricText}>
                {rubricRoot + ' /'}
              </div>
            </div>
          )}
          <SmartImage
            src={originalCover || (isMobile ? DEFAULT_CARD_COVER_SQUARE : DEFAULT_CARD_COVER)}
            previewSrc={previewCover}
            aspectRatio={+(imageWidth / imageHeight).toFixed(2)}
            maxWidth={imageWidth}
            {...highPriorityImage && { fetchPriority: 'high', instant: true }}
          />
          <div className={styles.overlay} />
        </div>
        <div className={styles.content} data-qa='card4-content'>
          <div className={styles.text}>
            <div className={styles.headline} data-qa='card4-headline'>{listHeadline || headline}</div>
            <div className={styles.altHeadline} data-qa='card4-alt-headline'>{altHeadline}</div>
          </div>
          <div className='date' data-qa='card4-date'>
            {cardDateFormat(publishedAt, { forceDisableYear: true })}
          </div>
        </div>
      </Link>
    </div>
  );
}

Card4.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4]),
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /**
  * Использование fetchPriority для загрузки картинки
  */
  highPriorityImage: PropTypes.bool,
};

Card4.defaultProps = {
  type: 0,
};

const Card = cardHOC(Card4);

Card.displayName = 'Card4';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card4 as StorybookComponent };
