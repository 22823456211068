export default {
  1: { size: 's' },
  2: { size: 'm' },
  3: { size: 'm', withRubricOnImage: true },
  4: { size: 's', withRubricOnImage: true },
  5: {
    size: 'bgHint',
    withBackground: true,
    withRubricBottom: true,
    withoutDate: true,
    withoutBorderRadius: true,
    withoutDefaultCover: true,
  },
  6: {
    size: 'bgContent',
    withBackground: true,
    hideImage: true,
    withRubricBottom: true,
    withoutDate: true,
    withoutBorderRadius: true,
    withoutDefaultCover: true,
  },
  7: { size: 'special', withoutDate: true, withoutBorderRadius: true },
  8: { size: 's', hideImage: true },
  9: { size: 'm', hideImage: true },
};
