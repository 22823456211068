export default {
  s: {
    headlineSize: '20px/24px',
    altHeadlineSize: '18px/24px',
    width: 258,
    height: 154,
    bgThemeColor: 'content',
  },
  bgHint: {
    headlineSize: '16px/20px',
    altHeadlineSize: '15px/18px',
    width: 258,
    height: 154,
    bgThemeColor: 'placeholder',
  },
  bgContent: {
    headlineSize: '16px/20px',
    altHeadlineSize: 0,
    width: 258,
    height: 154,
    bgThemeColor: 'content',
  },
  m: {
    headlineSize: '26px/30px',
    altHeadlineSize: '18px/24px',
    width: 402,
    height: 240,
    bgThemeColor: 'content',
  },
  special: {
    headlineSize: '18px/22px',
    altHeadlineSize: 0,
    width: 194,
    height: 118,
  },
};
