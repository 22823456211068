import PropTypes from 'prop-types';

import { NegativeMobile } from 'core/components/Wrappers';

import TopicCoverImage from './TopicCoverImage/index';
import TopicCoverGallery from './TopicCoverGallery';
import TopicCoverVideo from './TopicCoverVideo';

const topicTypeMap = {
  news: { cover: TopicCoverImage },
  article: { cover: TopicCoverImage },
  gallery: { cover: TopicCoverGallery },
  video: { cover: TopicCoverVideo },
};

export default function TopicCover({ content }) {
  const topicType = content.attributes.topic_type;
  const coverType = topicTypeMap[topicType] || null;

  if (!coverType) return null;

  const Cover = topicTypeMap[topicType].cover;

  return (
    <NegativeMobile>
      <Cover content={content} />
    </NegativeMobile>
  );
}

TopicCover.propTypes = {
  content: PropTypes.object.isRequired,
};
