import { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import resolve from 'core/resolver/resolve';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import AuthorPage from 'core/components/AuthorPage';

import { filterRequiredParams } from 'core/utils/api';

import PageLayout from 'site/components/PageLayout';

import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';

import FooterAds from 'site/components/Ads/FooterAds';
import { SideIndent } from 'core/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const Card2Type3 = bindProps({ type: 3 })(Card2);

function Author({ topics, author, isMobile }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      <PageLayout>
        <AuthorPage
          topics={topics}
          author={author}
          card={isMobile ? Card2Type3 : Card6}
          interitemSpacing={vertical}
        />
      </PageLayout>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    limit: 10,
    include: filterRequiredParams([Card6], 'include'),
    fields: filterRequiredParams([Card2, Card6], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Author));
