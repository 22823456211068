import oneLine from 'common-tags/lib/oneLine';

import PTAstraSansRegular from './PTAstraSans/PTAstraSans-Regular.woff';
import PTAstraSansRegular2 from './PTAstraSans/PTAstraSans-Regular.woff2';
import PTAstraSansBold from './PTAstraSans/PTAstraSans-Bold.woff';
import PTAstraSansBold2 from './PTAstraSans/PTAstraSans-Bold.woff2';

import PTAstraSerifRegular from './PTAstraSerif/PTAstraSerif-Regular.woff';
import PTAstraSerifRegular2 from './PTAstraSerif/PTAstraSerif-Regular.woff2';
import PTAstraSerifItalic from './PTAstraSerif/PTAstraSerif-Italic.woff';
import PTAstraSerifItalic2 from './PTAstraSerif/PTAstraSerif-Italic.woff2';
import PTAstraSerifBold from './PTAstraSerif/PTAstraSerif-Bold.woff';
import PTAstraSerifBold2 from './PTAstraSerif/PTAstraSerif-Bold.woff2';
import PTAstraSerifBoldItalic from './PTAstraSerif/PTAstraSerif-BoldItalic.woff';
import PTAstraSerifBoldItalic2 from './PTAstraSerif/PTAstraSerif-BoldItalic.woff2';

import MontserratAlternates800 from './Montserrat-alternates/montserrat-alternates-800.woff';
import MontserratAlternates8002 from './Montserrat-alternates/montserrat-alternates-800.woff2';
import Montserrat500 from './Montserrat/Montserrat500.woff';
import Montserrat5002 from './Montserrat/Montserrat500.woff2';


const fonts = {
  text: 'PTAstraSerif, Times New Roman, serif',
  display: 'Montserrat-alternates, Montserrat Alternates-fallback, sans-serif',
  display2: 'Montserrat, Montserrat-fallback, sans-serif',
  nuance: 'PTAstraSans, Arial, sans-serif',
  faces: [
    {
      fontFamily: 'PTAstraSans',
      src: oneLine`
        local('PTAstraSans Regular'),
        local('PTAstraSans-Regular'),
        local('PT Astra Sans Regular'),
        url(${PTAstraSansRegular2}) format('woff2'),
        url(${PTAstraSansRegular}) format('woff')
      `,
    },
    {
      fontFamily: 'PTAstraSans',
      fontWeight: 700,
      src: oneLine`
        local('PTAstraSans Bold'),
        local('PTAstraSans-Bold'),
        local('PT Astra Sans Bold'),
        url(${PTAstraSansBold2}) format('woff2'),
        url(${PTAstraSansBold}) format('woff')
      `,
    },
    {
      fontFamily: 'PTAstraSerif',
      src: oneLine`
        local('PTAstraSerif Regular'),
        local('PTAstraSerif-Regular'),
        local('PT Astra Sans Regular'),
        url(${PTAstraSerifRegular2}) format('woff2'),
        url(${PTAstraSerifRegular}) format('woff')
      `,
    },
    {
      fontFamily: 'PTAstraSerif',
      fontStyle: 'italic',
      src: oneLine`
        local('PTAstraSerif Italic'),
        local('PTAstraSerif-Italic'),
        local('PT Astra Sans Italic'),
        url(${PTAstraSerifItalic2}) format('woff2'),
        url(${PTAstraSerifItalic}) format('woff')
      `,
    },
    {
      fontFamily: 'PTAstraSerif',
      fontWeight: 700,
      src: oneLine`
        local('PTAstraSerif Bold'),
        local('PTAstraSerif-Bold'),
        local('PT Astra Sans Bold'),
        url(${PTAstraSerifBold2}) format('woff2'),
        url(${PTAstraSerifBold}) format('woff')
      `,
    },
    {
      fontFamily: 'PTAstraSerif',
      fontWeight: 700,
      fontStyle: 'italic',
      src: oneLine`
        local('PTAstraSerif BoldItalic'),
        local('PTAstraSerif-BoldItalic'),
        local('PT Astra Sans BoldItalic'),
        url(${PTAstraSerifBoldItalic2}) format('woff2'),
        url(${PTAstraSerifBoldItalic}) format('woff')
      `,
    },
    {
      fontFamily: 'Montserrat-alternates',
      fontWeight: 800,
      src: oneLine`
        local('Montserrat-alternates'),
        url(${MontserratAlternates8002}) format('woff2'),
        url(${MontserratAlternates800}) format('woff')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      src: oneLine`
        local('Montserrat-alternates'),
        url(${Montserrat5002}) format('woff2'),
        url(${Montserrat500}) format('woff')
      `,
    },
    {
      fontFamily: 'Montserrat-fallback',
      sizeAdjust: '113.40000000000003%',
      ascentOverride: '84%',
      src: 'local("Arial")',
    },
    {
      fontFamily: 'Montserrat Alternates-fallback',
      sizeAdjust: '115.89999999999995%',
      ascentOverride: '80%',
      src: 'local("Arial")',
    },
  ],
};

export default fonts;
