import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import cardHOC from 'core/components/card';

import { cardDateFormat } from 'site/utils';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'published_at',
];

function CardNews(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    published_at: publishedAt,
  } = content.attributes;

  return (
    <Link
      to={link}
      type='secondary'
      className={styles.link}
    >
      <div className={styles.cardNews} data-qa='card-news-item'>
        <style jsx>{`
          .${styles.cardNews}
            &:before
              background ${theme.colors.divider}

            &:hover
              .${styles.headline}
                color ${theme.colors.active1}

          .${styles.headline}
            font 700 24px/30px ${theme.fonts.nuance}
            color ${theme.colors.primary}
            transition color ${theme.animations.hover}

          .${styles.altHeadline}
            font 16px/22px ${theme.fonts.nuance}
            color ${theme.colors.primary}

          .${styles.meta}
            background ${theme.colors.content}

          .date
            font 14px/18px ${theme.fonts.nuance}
            color ${theme.colors.hint}
        `}</style>
        <div className={styles.headline} data-qa='card-news-headline'>{listHeadline || headline}</div>
        {altHeadline && <div className={styles.altHeadline}>{altHeadline}</div>}
        <div className={styles.meta}>
          <div className='date'>{cardDateFormat(publishedAt)}</div>
        </div>
      </div>
    </Link>
  );
}

CardNews.propTypes = {
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = cardHOC(CardNews);

Card.displayName = 'CardNews';
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardNews as StorybookComponent };
