import PropTypes from 'prop-types';

import Socializator from 'core/components/Socializator';
import GalleryWithAds from 'site/components/GalleryWithAds';
import { Indent } from 'core/components/Wrappers';

function TopicCoverGallery({ content }) {
  const {
    relationships: {
      photo_gallery: {
        data: photoGallery,
      },
    },
  } = content;

  return (
    <>
      <GalleryWithAds
        photoGallery={photoGallery}
        hashNavigation
        adsToReload={[
          {
            name: 'Billboard',
            count: 4,
          },
          {
            name: '240x400',
            count: 4,
          },
          {
            name: 'TopBanner',
            count: 4,
          },
        ]}
      />
      <Indent top={20}>
        <Socializator />
      </Indent>
    </>
  );
}

TopicCoverGallery.propTypes = {
  content: PropTypes.object,
};

export default TopicCoverGallery;
