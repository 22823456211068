import PropTypes from 'prop-types';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

function PageLayout({ children, showBouesque }) {
  return (
    <PageIndent>
      <ColumnLayout
        rightColumn={(
          <InnerSideColumn showBouesque={showBouesque} />
        )}
      >
        {children}
        <Boroda />
      </ColumnLayout>
    </PageIndent>
  );
}

PageLayout.propTypes = {
  showBouesque: PropTypes.bool,
};

export default PageLayout;
