import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import TopicContent from 'core/components/TopicContent';
import { TopicContentMaxWidth } from 'core/components/Wrappers';

import Sponsored from 'site/components/Ads/Sponsored';

import { TOPIC_VIKONTS_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';
import { capitalize } from 'site/utils';

// eslint-disable-next-line import/no-namespace
import * as Vikonts from 'site/vikonts';

import {
  InRead,
  Native3,
  AdCenter,
} from 'site/components/Ads/desktop';

import {
  InPage,
  Content1,
  Content2,
  Content3,
  Content4,
  ContentSpec,
} from 'site/components/Ads/mobile';

const desktopAds = [
  {
    before: 400,
    after: 400,
    showOnlyOnce: true,
    renderer: AdCenter,
  },
  {
    before: 1200,
    after: 200,
    showOnlyOnce: true,
    renderer: InRead,
  },
  {
    before: 1200,
    after: 800,
    renderer: Native3,
  },
  {
    before: 1200,
    after: 200,
    renderer: AdCenter,
  },
];

const mobileAds = [
  Content1,
  InPage,
  ContentSpec,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 1200,
  after: 800,
  showOnlyOnce: ads.length !== index + 1,
  renderer: renderer,
}));

const blocks = [
  'image',
  'photoGallery',
  'incut',
  'opinion',
  'readMore',
  'number',
  'snippet',
  'pageBreak',
].reduce((items, renderer) => {
  const VikontComponent = Vikonts[capitalize(renderer)];
  items[renderer] = (block, parentProps, ctx) => (
    <VikontComponent {...{ block, parentProps, ctx }} />
  );
  return items;
}, {});

function SiteTopicContent({ content, theme }) {
  const {
    attributes: {
      topic_type: topicType,
    },
  } = content;

  const isCard = topicType === 'card';

  return (
    <div className={cx(isCard && 'topicCard')} data-qa='topic-card'>
      <style jsx>{`
        .topicCard
          counter-reset topicCardCount
          background ${theme.colors.layout}
          padding 0 0 ${TOPIC_VIKONTS_INDENT}px 0
          @media (max-width: 1200px)
            padding 0 ${SIDE_INDENT_MOBILE}px ${TOPIC_VIKONTS_INDENT}px
      `}</style>

      <TopicContentMaxWidth>
        <Sponsored />
      </TopicContentMaxWidth>

      <TopicContent
        topicType={topicType}
        interitemSpacing={TOPIC_VIKONTS_INDENT}
        {...{
          blocks,
          mobileAds,
          desktopAds,
        }}
        {...isCard && { disableMultipage: true }}
      />
    </div>
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(SiteTopicContent);
