import PropTypes from 'prop-types';
import withTheme from 'core/components/theme';

import './index.styl';

function Card2Styles({ theme }) {
  return (
    <style jsx global>{`
      .native-ad-1
      .native-ad-2
        .ad_native_title
          color ${theme.colors.primary}

        .ad_native_desc
          color ${theme.colors.primary}

        .ad_label__text
          font 16px/22px ${theme.fonts.nuance}
          color ${theme.colors.hint}

        .adv_info__text
          font 14px/1.3 ${theme.fonts.nuance}
    `}</style>
  );
}

Card2Styles.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Card2Styles);
