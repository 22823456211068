import { Fragment } from 'react';
import PropTypes from 'prop-types';

import ThemeSwitch from 'core/components/ThemeSwitch';

import Content from './Content';
import types from './types';

export default function CoverBG(props) {
  const { children, ...otherProps } = props;
  const { theme: themeName } = types[props.type] || {};

  let Wrapper = Fragment;
  let themeProps;

  if (themeName) {
    Wrapper = ThemeSwitch;
    themeProps = { name: themeName };
  }

  return (
    <Wrapper {...themeProps}>
      <Content {...otherProps}>
        {children}
      </Content>
    </Wrapper>
  );
}

CoverBG.propTypes = {
  /** Тип подложки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4]).isRequired,
  /** Дочерний компонент, который будет отображен с данной подложкой */
  children: PropTypes.node,
  /** Флаг, управляющий нижним отступом в мобильном лейауте */
  hidePaddingBottom: PropTypes.bool,
};

CoverBG.defaultProps = {
  type: 0,
  hidePaddingBottom: false,
};
