import PropTypes from 'prop-types';

import readMore from 'core/components/TopicContent/blocks/readMore';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import bindProps from 'core/components/bindProps';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';


const Card3Type7 = bindProps({ type: 7 })(Card3);
const Card2Type1 = bindProps({ type: 1 })(Card2);

export default function ReadMore(props) {
  const {
    block,
    parentProps: {
      isMobile,
    },
  } = props;

  return (
    <div className='readMore'>
      {readMore(
        block,
        {
          title: 'Читайте на тему:',
          singleCard: Card3Type7,
          feedCard: Card3Type7,
          drumCard: isMobile ? Card3Type7 : Card2Type1,
        }
      )}
    </div>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};
