import color from 'core/libs/color';

const colors = {
  layout: '#303030',
  content: '#303030',
  active1: '#CC442A',
  active2: '#CC442A',
  accent: '#efe5d4',
  error: '#ff3e56',
  primary: '#fff',
  input: '#fff',
  logo: '#fff',
  divider: color('#EAEAEA').alpha(0.2).string(),
  get galleryIconsBackground() {
    return this.layout;
  },
  get hint() {
    return color(this.primary).alpha(0.7).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() {
    return this.active1;
  },
};

const button = {
  primary: {
    idle: {
      color: colors.content,
      background: colors.primary,
      border: 'none',
    },

    hover: {
      color: colors.primary,
      background: colors.content,
      border: 'none',
    },

    active: {
      color: colors.content,
      background: colors.primary,
      border: 'none',
    },

    disabled: {
      color: colors.content,
      background: colors.primary,
      border: 'none',
    },
  },

  secondaryDark: {
    idle: {
      color: '#5d5d5d',
      background: 'none',
      border: '1px solid #5d5d5d',
    },

    hover: {
      color: '#000',
      background: 'none',
      border: '1px solid #000',
    },

    active: {
      color: '#5d5d5d',
      background: 'none',
      border: '1px solid #5d5d5d',
    },

    disabled: {
      color: '#5d5d5d',
      background: 'none',
      border: `1px solid ${color(colors.hint).alpha(0.4).string()}`,
    },
  },
};

const link = {
  tertiary: {
    hover: {
      color: colors.primary,
      decoration: 'underline',
    },

    active: {
      color: color(colors.active1).mix(color(colors.primary), 0.8).string(),
      decoration: 'underline',
    },

    current: {
      decoration: 'none',
    },

    visited: {
      color: colors.primary,
    },
  },
};

const bullet = {
  const: {
    size: '7px',
  },

  basic: {
    idle: {
      background: colors.placeholder,
    },

    active: {
      background: colors.primary,
    },
  },
};

const login = {
  popup: {
    content: {
      color: colors.primary,
    },
  },
};

export default {
  colors,
  controls: {
    link,
    button,
    bullet,
    login,
  },
};
