import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import './index.styl';

const NativeStyles = ({ theme }) => {
  return (
    <Fragment>
      <style jsx global>{`
        .listing-spec-search-native
          .ad_native_title
            color ${theme.colors.primary}
            font 700 24px/30px ${theme.fonts.nuance}

          .ad_label__text
            font 16px/22px ${theme.fonts.nuance}
            color ${theme.colors.primary}
  
          .adv_info__text
            font 14px/1.3 ${theme.fonts.nuance}

          .ad_native_wrapper
            border-bottom 1px solid ${theme.colors.divider}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function ListingSpecSearchNative(Component) {
  return withTheme(props => (
    <div className='native-ad listing-spec-search-native'>
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
