import PropTypes from 'prop-types';

import skip from 'core/resolver/skip';

import Link from 'core/components/Link';

import withTheme from 'core/components/theme';

import { dateFormat } from 'core/utils/dates';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'published_at',
];


function Card1(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const time = dateFormat(publishedAt, 'HH:mm');

  return (
    <div className='card1'>
      <style jsx>{`
        .headline
          font 18px/24px ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.time}
          font 14px/20px ${theme.fonts.nuance}
          color ${theme.colors.hint}
          transition color ${theme.animations.hover}

        .card1:hover
          .headline
            color ${theme.colors.active1}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.content}>
          <div className='headline' data-qa='card1-headline'>
            <span className={styles.time} data-qa='card1-time'>{time}</span>
            {listHeadline || headline}
          </div>
        </div>
      </Link>
    </div>
  );
}

Card1.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

Card1.defaultProps = {
  type: 0,
};

const Card = skip(withTheme(Card1));

Card.displayName = 'Card1';
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card1 as StorybookComponent };
