import { Fragment } from 'react';
import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Feed from 'core/components/Feed';
import Mjolnir from 'core/components/Mjolnir';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Desktop, Mobile } from 'core/components/breakpoint';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import CoverBG from 'site/components/CoverBG';
import { Indent } from 'core/components/Wrappers';

import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import CardMain from 'site/cards/CardMain';

import {
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  VERTICAL_INDENT_ADV_TOP,
  VERTICAL_INDENT_ADV_BOTTOM,
} from 'site/constants';

import {
  SuperFooter,
  Native1AsCard2Type4,
} from 'site/components/Ads/desktop';

import { Listing1 } from 'site/components/Ads/mobile';


const Card4Type1 = bindProps({ type: 1 })(Card4);
const Card4Type2 = bindProps({ type: 2, highPriorityImage: true })(Card4);
const Card3Type2 = bindProps({ type: 2 })(Card3);
const CardMainType3 = bindProps({ type: 3 })(CardMain);
const CardMainType4 = bindProps({ type: 4 })(CardMain);

function MainListPart1({ isDesktop, topics }) {
  const vertical = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;
  const verticalAdvTop = isDesktop ? VERTICAL_INDENT_ADV_TOP : VERTICAL_INDENT_MOBILE;
  const verticalAdvBottom = isDesktop ? VERTICAL_INDENT_ADV_BOTTOM : 0;

  const HeroCard = isDesktop ? (
    Card4Type2
  ) : ({ content }) => (
    <Indent left={-SIDE_INDENT_MOBILE} right={-SIDE_INDENT_MOBILE}>
      <Card4Type1 content={content} highPriorityImage />
    </Indent>
  );

  const first = topics.slice(0, 3);
  const second = topics.slice(3, 6);

  return (
    <Fragment>
      {first.length > 0 && (
        <CoverBG type={1}>
          <Mjolnir
            heroCard={HeroCard}
            card={CardMainType3}
            interitemSpacing={vertical}
            content={first}
            dataQa='main-list-first'
          />
          <AdWrapper
            top={verticalAdvTop}
            bottom={verticalAdvBottom}
          >
            <Desktop>
              <SuperFooter />
            </Desktop>
            <Mobile>
              <Listing1 />
            </Mobile>
          </AdWrapper>
        </CoverBG>
      )}

      {second.length > 0 && (
        <CoverBG type={2}>
          <Feed
            grid
            columns={3}
            card={isDesktop ? CardMainType4 : Card3Type2}
            interitemSpacing={vertical}
            content={second}
            cardsReplacement={{
              1: replaceCardWithAd(Native1AsCard2Type4, isDesktop),
            }}
          />
        </CoverBG>
      )}
    </Fragment>
  );
}

MainListPart1.propTypes = {
  isDesktop: PropTypes.bool,
  topics: PropTypes.array,
  content: PropTypes.object,
};

export default withBreakpoint(MainListPart1);
