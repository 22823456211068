import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'core/libs/helmet';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import TagTopics from 'core/components/TagTopics';
import withPageHocs from 'core/components/withPageHocs';

import { filterRequiredParams } from 'core/utils/api';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import PageLayout from 'site/components/PageLayout';

import FooterAds from 'site/components/Ads/FooterAds';
import { SideIndent } from 'core/components/Wrappers';
import Headline from 'site/components/Headline';
import News from 'site/components/News';

import newsFetcher from 'site/fetchers/news';

import Card2 from 'site/cards/Card2';

import SectionContent from './Content';


function TagPage({ tag, topics, news }) {
  return (
    <Fragment>
      <PageLayout>
        <TagTopics
          tag={tag}
          topics={topics}
          limit={RUBRICS_PAGE_LIMIT}
          headerComponent={Headline}
        >
          {props => {
            // eslint-disable-next-line react/prop-types
            const { title, titleMeta } = props;

            return (
              <Fragment>
                <Helmet title={`#${titleMeta || title}`} />
                <News topics={news} />
                <SectionContent {...props} />
              </Fragment>
            );
          }}
        </TagTopics>
      </PageLayout>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
  news: PropTypes.array,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: topicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: filterRequiredParams([Card2], 'include'),
    fields: filterRequiredParams([Card2], 'fields'),
  }),
  news: newsFetcher(),
});

export default withPageHocs(dataProvider)(TagPage);
