import PropTypes from 'prop-types';
import cx from 'classnames';

import modelPropTypes, { numberAttributes } from 'core/utils/prop-types/model';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

export default function Number(props) {
  const {
    parentProps,
    block: {
      attributes: {
        body,
        value,
        unit,
      },
    },
  } = props;

  const {
    theme,
    topicType,
  } = parentProps;

  const isCard = topicType === 'card';

  return (
    <div className={cx(
      'number',
      styles.number,
      isCard && styles.isCard,
    )}
    >
      <style jsx>{`
        .number
          border-top 2px solid ${theme.colors.divider}
          border-bottom 2px solid ${theme.colors.divider}

        .valueBox
          color ${theme.colors.active1}
          font-family ${theme.fonts.display}

          &:before
          &:after
            background ${theme.colors.active1}

        .unit
          color ${theme.colors.active1}
          font-family ${theme.fonts.display}

        .body
          color ${theme.colors.primary}
          font-family ${theme.fonts.nuance}
      `}</style>
      <div className={styles.value}>
        <span className={cx(styles.valueBox, 'valueBox')}>{value}</span>
      </div>
      {unit && <div className={cx(styles.unit, 'unit')}>{unit}</div>}
      <div className={cx(styles.body, 'body')}>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </div>
    </div>
  );
}

Number.propTypes = {
  block: modelPropTypes(numberAttributes).isRequired,
  parentProps: PropTypes.object,
};
