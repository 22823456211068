import PropTypes from 'prop-types';

import modelPropTypes, { imageAttributes } from 'core/utils/prop-types/model';

import coreImage from 'core/components/TopicContent/blocks/image';
import EmptyWrapper from 'core/components/EmptyWrapper';
import { TopicContentMaxWidth } from 'core/components/Wrappers';

export default function Image({
  block,
  parentProps,
  ctx,
}) {
  const {
    topicType,
  } = parentProps;

  const isCard = topicType === 'card';

  const Wrapper = isCard ? TopicContentMaxWidth : EmptyWrapper;

  return (
    <Wrapper>
      {coreImage(block, parentProps, ctx)}
    </Wrapper>
  );
}

Image.propTypes = {
  block: modelPropTypes(imageAttributes).isRequired,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
