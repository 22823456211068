import cx from 'classnames';
import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import modelPropTypes from 'core/utils/prop-types/model';

import H3 from 'core/components/H3';
import { TopicContentMaxWidth } from 'core/components/Wrappers';


import { TOPIC_VIKONTS_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


/**
 * Компонент стилизован для топиков типа "Карточка".
 * В таком топике визуальный разрыв между блоками виджетов происходит благодаря этому компоненту.
 */
export default function PageBreak(props) {
  const {
    block,
    parentProps,
  } = props;

  const {
    theme,
    topicType,
  } = parentProps;

  const isCard = topicType === 'card';

  if (!isCard) return null;

  const title = get(block, 'attributes.title');

  return (
    <div className={cx('pageBreak', styles.pageBreak)}>
      <style jsx>{`
        .pageBreak
          padding-top ${TOPIC_VIKONTS_INDENT * 2}px

          &:after
            background ${theme.colors.content}
            height ${TOPIC_VIKONTS_INDENT}px

            @media (max-width: 1200px)
              margin-left -${SIDE_INDENT_MOBILE}px
              margin-right -${SIDE_INDENT_MOBILE}px

          &:before
            color ${theme.colors.primary}
            font-family ${theme.fonts.nuance}
            border-bottom 2px solid ${theme.colors.primary}
            top ${TOPIC_VIKONTS_INDENT + 30}px
      `}</style>
      <div className={cx(styles.content, 'content')}>
        <TopicContentMaxWidth>
          <H3 is='h2'>{title}</H3>
        </TopicContentMaxWidth>
      </div>
    </div>
  );
}

PageBreak.propTypes = {
  block: modelPropTypes(PropTypes.shape({
    title: PropTypes.string.isRequired,
    caption: PropTypes.string,
  })).isRequired,
  parentProps: PropTypes.object,
};
