import PropTypes from 'prop-types';
import Video from 'core/components/Video';
import resolveRelationships from 'core/utils/relationships';

const relationships = resolveRelationships(
  ['video'],
  {},
  { video: {} },
);

export default function TopicCoverVideo({ content }) {
  const {
    video: {
      code, provider,
    },
  } = relationships(content);

  if (!code || !provider) return null;

  return <Video code={code} providerName={provider} />;
}

TopicCoverVideo.propTypes = {
  content: PropTypes.object,
};
