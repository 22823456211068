import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTheme from 'core/components/theme';

import { VERTICAL_INDENT_MOBILE } from 'site/constants';

import types from './types';
import styles from './index.styl';

function Content(props) {
  const { children, type, breakpoint, theme, hidePaddingBottom } = props;

  const { yAxisOffset, theme: themeName } = types[type] || {};

  return (
    <div className={cx(
      'coverBg',
      breakpoint,
      styles.coverBg,
      styles[breakpoint],
    )}
    >
      <style jsx>{`
        .coverBg
          &:before
            background ${themeName ? theme.colors.layout : 'transparent'}

          &.desktop
            padding-bottom ${themeName ? 20 : 0}px

            &:before
              top ${yAxisOffset === 'top' ? 150 : 0}px
              bottom ${yAxisOffset === 'bottom' ? 300 : 0}px

          &.mobile
            padding-bottom ${!hidePaddingBottom && themeName ? VERTICAL_INDENT_MOBILE : 0}px
      `}</style>
      <div className={cx(styles.content, 'content')}>
        {children}
      </div>
    </div>
  );
}

Content.propTypes = {
  type: PropTypes.oneOf([0, 1, 2, 3, 4]).isRequired,
  children: PropTypes.node,
  hidePaddingBottom: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

Content.defaultProps = {
  type: 0,
};

export default withTheme(withBreakpoint(Content));
