import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import './global.styl';

const GlobalNativeStyles = ({ theme }) => {
  return (
    <style jsx global>{`
      .native-ad
        .ad_native_img
          background-color ${theme.colors.hint}

          &:before
            background ${theme.colors.primary}
            transition opacity ${theme.animations.hover}

          &:after
            transition opacity ${theme.animations.hover}

        .ad_native_title
          transition color ${theme.animations.hover}
        &:hover
          .ad_native_title
            color ${theme.colors.active1}
    `}</style>
  );
};

GlobalNativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(GlobalNativeStyles);
