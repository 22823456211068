export default {
  mobile: {
    headlineSize: '22px/24px',
    altHeadlineSize: '18px/20px',
    width: 300,
    height: 240,
  },
  desktop: {
    headlineSize: '20px/22px',
    altHeadlineSize: '16px/18px',
    width: 240,
    height: 162,
  },
};
