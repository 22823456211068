import PropTypes from 'prop-types';

import ColumnLayout from 'core/components/ColumnLayout';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { PageIndent } from 'core/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';


export default function TopicWrapper(props) {
  const { children, infinityIndex, content } = props;
  const {
    topic_types: topicType,
    is_premium: isPremium,
  } = content?.attributes || {};

  const Wrapper = isPremium ? EmptyWrapper : ColumnLayout;

  return (
    <PageIndent>
      <Wrapper
        rightColumn={(
          <InnerSideColumn
            show240x200
            infinityIndex={infinityIndex}
            shortContent={topicType === 'news'}
          />
        )}
      >
        {children}
      </Wrapper>
    </PageIndent>
  );
}

TopicWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.object.isRequired,
  infinityIndex: PropTypes.number,
  /**
   * Страница комментариев
   */
  comments: PropTypes.bool,
};
