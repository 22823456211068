import {
  ru,
  format,
  isSameYear,
  isSameDay,
  subDays,
} from 'core/libs/date-fns';
import { get } from 'core/libs/lodash';

export function cardDateFormat(isoDate, { forceDisableYear } = {}) {
  const date = new Date(isoDate);
  const now = Date.now();

  let pattern = 'dd MMMM yyyy';

  if (isSameDay(date, now)) {
    pattern = 'Сегодня в HH:mm';
  } else if (isSameDay(date, subDays(now, 1))) {
    pattern = 'Вчера в HH:mm';
  } else if (isSameYear(date, now) || forceDisableYear) {
    pattern = 'dd MMMM';
  }

  return format(date, pattern, { locale: ru });
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Формирует ссылку на рубрику
 */
export function buildRubricUrl(slug, rootSlug) {
  if ((slug === rootSlug) || !rootSlug) {
    return `/${slug}`;
  }
  return `/${rootSlug}/${slug}`;
}

/**
 * Получаем рандомное целое число, включая минимальное и минимальное значения
 */
export function getRandomIntInclusive(min, max) {
  const minVal = Math.ceil(min);
  const maxVal = Math.floor(max);
  return Math.floor(Math.random() * (maxVal - minVal + 1)) + minVal;
}

/**
 * Возвращает url указанной версии аватара.
 * @param {Object} [person={}] - объект, соответствующий модели person
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {Object} Объект аватара указанной версии или аватар-заглушка.
 */

export function getAvatar(person = {}, version = 'original') {
  const path = [
    'attributes',
    'avatar',
    'attributes',
    'versions',
    version,
  ];

  const defaultAvatar = {
    rel_url: require('site/icons/avatar.png'),
    width: 400,
    height: 400,
  };

  return get(person, path, defaultAvatar);
}
