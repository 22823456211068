import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import H1 from 'core/components/H1';
import AuthorsPage from 'core/components/AuthorsPage';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Indent, SideIndent } from 'core/components/Wrappers';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import PageLayout from 'site/components/PageLayout';

import FooterAds from 'site/components/Ads/FooterAds';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import { TopBanner } from 'site/components/Ads/mobile';


function Authors({ rawAuthors, isMobile }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      <PageLayout>
        <Mobile>
          <AdWrapper bottom={vertical}>
            <TopBanner />
          </AdWrapper>
        </Mobile>
        <H1>Авторы</H1>
        <Indent bottom={vertical} />
        <AuthorsPage rawAuthors={rawAuthors} />
      </PageLayout>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
  isMobile: PropTypes.bool,
};


const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(Authors));
