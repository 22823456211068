import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'core/libs/lodash';

import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import modelPropTypes, { opinionAttributes } from 'core/utils/prop-types/model';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

export default function Opinion(props) {
  const {
    block,
    parentProps,
  } = props;

  const {
    attributes: {
      author,
      body,
    },
  } = block;

  const avatar = get(author, 'attributes.avatar');
  const name = get(author, 'attributes.name');
  const jobTitle = get(author, 'attributes.job_title');

  const {
    theme,
    isMobile,
    topicType,
  } = parentProps;

  const isCard = topicType === 'card';

  return (
    <div className={cx(
      styles.opinion,
      isCard && styles._isCard,
    )}
    >
      <style jsx>{`
        .${styles.opinion}
          border-top 2px solid ${theme.colors.divider}
          border-bottom 2px solid ${theme.colors.divider}

          &.${styles.isCard}
            border none

        .${styles.content}
          margin 0 ${isMobile ? SIDE_INDENT_MOBILE + 'px' : 0}

          &:before
            font 72px/83px ${theme.fonts.nuance}
            color ${theme.colors.active1}

        .${styles.body}
          font 20px/32px ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.name}
          font 700 16px/20px ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.jobTitle}
          font 700 14px/18px ${theme.fonts.nuance}
          color ${theme.colors.hint}
      `}</style>
      <div className={styles.content}>
        <div className={styles.body}>
          <MarkdownWrapper>
            {body}
          </MarkdownWrapper>
        </div>
        {author && (
          <div className={styles.author}>
            {avatar && (
              <div className={styles.avatar}>
                <Image
                  width={60}
                  aspectRatio={1}
                  src={get(avatar, 'attributes.versions.original')}
                />
              </div>
            )}
            <div className={styles.meta}>
              <p className={styles.name}>
                {name}
              </p>
              {jobTitle && (
                <p className={styles.jobTitle}>
                  {jobTitle}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

Opinion.propTypes = {
  parentProps: PropTypes.object,
  block: modelPropTypes(opinionAttributes).isRequired,
};
