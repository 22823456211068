import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import mapSizes from 'site/cards/Card3/sizes';
import mapTypes from 'site/cards/Card3/types';

import './index.styl';

const CARD_TYPE = 2;

const NativeStyles = ({ theme }) => {
  const { [mapTypes[CARD_TYPE].size]: cardSizes } = mapSizes;

  const {
    headlineSize,
  } = cardSizes;

  return (
    <Fragment>
      <style jsx global>{`
        .listing-spec-native
          .ad_native_title
            color ${theme.colors.primary}
            font ${headlineSize} ${theme.fonts.nuance}

          .ad_native_sponsored_clone
            font 16px/22px ${theme.fonts.nuance}
            color ${theme.colors.hint}

          .adv_info__text
            font 16px/22px ${theme.fonts.nuance}
            color ${theme.colors.hint}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function ListingSpecNative(Component) {
  return withTheme(props => (
    <div className='native-ad listing-spec-native'>
      <NativeStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
