import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';

import Card2 from 'site/cards/Card2';
import Card5 from 'site/cards/Card5';

const requiredPayloadFields = [
  'topic_type',
];

function CardMain(props) {
  const {
    content: {
      attributes: {
        topic_type: topicType,
      },
    },
    content,
    type,
    position,
  } = props;

  const isCardTopic = topicType === 'card';

  return (
    <Fragment>
      {isCardTopic
        ? <Card5 type={type} content={content} />
        : (
          <Card2
            type={type}
            content={content}
            position={position}
          />
        )
      }
    </Fragment>
  );
}

CardMain.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([1, 2, 3, 4]),
  /** Контент топика */
  content: PropTypes.object,
  position: PropTypes.number,
};

const Card = CardMain;

Card.displayName = 'CardMain';
Card.requiredPayloadImports = filterRequiredParams([Card2, Card5], 'include');
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardMain as StorybookComponent };
