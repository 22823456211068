import { Fragment } from 'react';
import PropTypes from 'prop-types';

import topicProvider from 'core/resolver/data/topic';

import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import { SideIndent, TopicContentMaxWidth } from 'core/components/Wrappers';
import FooterAds from 'site/components/Ads/FooterAds';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';
import resolveRelationships from 'core/utils/relationships';

import Indent from 'core/components/Wrappers/Indent';

import TopicWrapper from 'site/pages/topic/TopicWrapper';
import TopicHeader from 'site/pages/topic/TopicHeader';


const relationships = resolveRelationships(['content'], {});

function LeaderBoard({ content, isMobile }) {
  return (
    <Fragment>
      <Topic content={content}>
        {topic => {
          const {
            content: {
              widgets,
            },
          } = relationships(topic);

          if (widgets.length === 0) return null;

          const {
            final_announce: finalAnnounce,
          } = widgets[0].attributes;

          return (
            <TopicWrapper>
              <Indent top={isMobile ? 16 : 20} bottom={20}>
                <TopicHeader content={topic} isGameCompare />
              </Indent>
              <TopicContentMaxWidth>
                <div>{finalAnnounce}</div>
              </TopicContentMaxWidth>
              <Indent top={25} bottom={isMobile ? 80 : 50}>
                <LeaderBoardCore content={topic} widget={widgets[0]} />
              </Indent>
            </TopicWrapper>
          );
        }}
      </Topic>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

LeaderBoard.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.object,
};

export default withPageHocs(topicProvider)(withBreakpoint(LeaderBoard));
