import { Fragment } from 'react';
import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import { Desktop, Mobile } from 'core/components/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import { Indent, NegativeMobile } from 'core/components/Wrappers';
import CoverBG from 'site/components/CoverBG';

import Feed from 'core/components/Feed';
import Drum from 'core/components/Drum';

import CardSelection from 'site/cards/CardSelection';
import CardSelectionSimple from 'site/cards/CardSelectionSimple';

import { SIDE_INDENT_MOBILE } from 'site/constants';


const CardSelectionSimpleMobile = bindProps({ type: 1 })(CardSelectionSimple);
const CardSelectionSimpleDesktop = bindProps({
  type: 2,
  imageHeightShift: 1 / 2,
})(CardSelectionSimple);

const relationships = resolveRelationships(...[
  ['read_more'],
  {},
  { read_more: { topic_ids: [] } },
]);

export default function Selection({ isDesktop, content }) {
  if (!content) return null;

  const {
    read_more: {
      topic_ids: topicIds,
    },
  } = relationships(content);

  return (
    <NegativeMobile>
      <CoverBG type={3}>
        <CardSelection type={isDesktop ? 2 : 1} content={content} />
        {topicIds.length > 0 && (
          <Fragment>
            <Desktop>
              <Indent left={22} right={22}>
                <Feed
                  grid
                  columns={topicIds.length}
                  card={CardSelectionSimpleDesktop}
                  ids={topicIds.join(',')}
                  interitemSpacing={22}
                />
              </Indent>
            </Desktop>
            <Mobile>
              <Indent
                left={SIDE_INDENT_MOBILE}
                right={SIDE_INDENT_MOBILE}
                top={-30}
              >
                <Drum
                  itemWidthMobile='100%'
                  card={CardSelectionSimpleMobile}
                  ids={topicIds.join(',')}
                />
              </Indent>
            </Mobile>
          </Fragment>
        )}
      </CoverBG>
    </NegativeMobile>
  );
}

Selection.propTypes = {
  isDesktop: PropTypes.bool,
  content: PropTypes.object,
};
