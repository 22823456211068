import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Desktop, Mobile } from 'core/components/breakpoint';

import Feed from 'core/components/Feed';

import Card1 from 'site/cards/Card1';

export default function NewsContent({ topics, theme }) {
  if (!topics) return null;

  const first = topics.slice(0, 3);
  const second = topics.slice(3, 6);
  const third = topics.slice(6);

  return (
    <Fragment>
      <style jsx>{`
        .divider
          background ${theme.colors.divider}
      `}</style>
      <style jsx>{`
        .feeds
          display flex
          align-items flex-start
        .feed
          flex 1 0 28%
        .divider
          width 1px
          margin 0 4%
          align-self stretch
          flex 0 0 1px
      `}</style>

      <div className='feeds' data-qa='feeds'>
        <Desktop>
          <div className='feed' data-qa='feed-first'>
            {first.length > 0 && <Feed
              content={first}
              card={Card1}
              interitemSpacing={15}
            />}
          </div>
          <div className='divider' />
          <div className='feed' data-qa='feed-second'>
            {second.length > 0 && <Feed
              content={second}
              card={Card1}
              interitemSpacing={15}
            />}
          </div>
          <div className='divider' />
          <div className='feed' data-qa='feed-third'>
            {third.length > 0 && <Feed
              content={third}
              card={Card1}
              interitemSpacing={15}
            />}
          </div>
        </Desktop>
        <Mobile>
          <Feed
            content={topics.slice(0, 5)}
            card={Card1}
            interitemSpacing={20}
          />
        </Mobile>
      </div>
    </Fragment>
  );
}

NewsContent.propTypes = {
  topics: PropTypes.array,
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
};
