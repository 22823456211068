export default {
  mobile: {
    headlineSize: '32px/1.2',
    altHeadlineSize: '18px/24px',
    width: 360,
    height: 440,
    padding: '25px 20px 20px',
  },
  desktop: {
    headlineSize: '54px/1.2',
    altHeadlineSize: '18px/24px',
    width: 834,
    height: 500,
    padding: '50px 40px 32px 72px',
  },
};
