import PropTypes from 'prop-types';

import withTopic from 'core/components/Topic/withTopic';
import { withBreakpoint } from 'core/components/breakpoint';

import { Indent } from 'core/components/Wrappers';
import { PartnetsHorizontal } from 'site/components/Partners';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

function Boroda({ isDesktop, topic }) {
  const spacing = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;

  if (!topic) {
    return null;
  }
  return (
    <Indent top={spacing} >
      <PartnetsHorizontal />
    </Indent>
  );
}

Boroda.propTypes = {
  isDesktop: PropTypes.bool,
  topic: PropTypes.object,
};

export default withBreakpoint(withTopic(Boroda));
