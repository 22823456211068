import cx from 'classnames';
import PropTypes from 'prop-types';

import MarkdownWrapper from 'core/components/MarkdownWrapper';
import H6 from 'core/components/H6';

import modelPropTypes from 'core/utils/prop-types/model';

import styles from './index.styl';

export default function Incut(props) {
  const {
    parentProps,
    block: {
      attributes: { body },
    },
  } = props;

  const {
    theme,
    topicType,
  } = parentProps;

  const isCard = topicType === 'card';

  return (
    <div className={cx(
      'incut',
      styles.incut,
      isCard && styles.isCard,
    )}
    >
      <style jsx>{`
        .incut
          &:before
          &:after
            background ${theme.colors.active1}
      `}</style>
      <H6>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </H6>
    </div>
  );
}

Incut.propTypes = {
  block: modelPropTypes(PropTypes.shape({
    body: PropTypes.string.isRequired,
  })).isRequired,
  parentProps: PropTypes.object,
};
