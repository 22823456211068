import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import { topicDateFormat } from 'core/utils/dates';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';

import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';

import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'listHeadline',
  'published_at',
];

const requiredPayloadImports = [
  'content',
];
const relationships = resolveRelationships(requiredPayloadImports, null, {
  content: {},
});


function CompareCard(props) {
  const {
    content,
    theme: {
      fonts,
      colors,
    },
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  if (!widgets || widgets?.length === 0) return null;

  const {
    first_announce: firstAnnounce,
  } = widgets[0]?.attributes || {};

  return (
    <Link
      className={styles.card}
      to={link}
      type='secondary'
    >
      <style jsx>{`
        .${styles.body}
          border-bottom 2px solid ${colors.divider}
          :global(.mobile) &
            border-bottom none

        .headline
          font 700 26px/29px ${fonts.nuance}
          color ${colors.primary}
          .${styles.wrapper}:hover &
            color ${colors.active1}

        .${styles.date}
          font 11px/14px ${fonts.text}
          color ${colors.hint}

        .${styles.firstAnnounce}
          font 18px/24px ${fonts.text}
      `}</style>
      <div className={styles.wrapper}>
        <Lightning
          imageMaxWidth={isMobile ? 550 : 360}
          height={215}
          widget={widgets[0]}
          progressType={4}
          lightningColor={colors.black}
          borderRadius={isMobile ? '5px' : '0px'}
        />
        <div className={styles.body}>
          <div className={styles.date}>{topicDateFormat(publishedAt)}</div>
          {(listHeadline || headline) && (
            <div className='headline'>
              {listHeadline || headline}
            </div>
          )}
          {firstAnnounce && (
            <Mobile>
              <div className={styles.firstAnnounce}>{firstAnnounce}</div>
            </Mobile>
          )}
        </div>
      </div>
    </Link>
  );
}

CompareCard.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = skip(withTheme(withBreakpoint(CompareCard)));

Card.displayName = 'CompareCard';
Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;

export { CompareCard as StorybookComponent };
