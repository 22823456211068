import Page from 'core/components/Page';

import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import { PageIndent, SideIndent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import FooterAds from 'site/components/Ads/FooterAds';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import CardMain from 'site/cards/CardMain';
import CardSelection from 'site/cards/CardSelection';
import CardSelectionSimple from 'site/cards/CardSelectionSimple';

import newsFetcher from 'site/fetchers/news';

import Content from './Content';


function MainPage(props) {
  return (
    <Page>
      <PageIndent>
        <div style={{ minHeight: 600 }}>
          <Content {...props} />
        </div>
      </PageIndent>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Page>
  );
}

const dataProvider = resolve({
  topics: ({ bebopApi, consoleError }) => {
    return bebopApi.getTopics({
      list: 'main',
      sort: 'list',
      include: filterRequiredParams([Card2, Card3, Card4, CardMain], 'include'),
      fields: filterRequiredParams([Card2, Card3, Card4, CardMain], 'fields'),
      limit: 15,
      topic_type: '-selection',
    })
      .then(denormalizeData)
      .catch(consoleError('topics list', []));
  },

  popular: ({ bebopApi, popular, consoleError }) => {
    return popular || bebopApi
      .getTopics({
        limit: 5,
        sort: '-views_count',
        include: filterRequiredParams([Card3], 'include'),
        fields: filterRequiredParams([Card3], 'fields'),
      })
      .then(denormalizeData)
      .catch(consoleError('popular list', []));
  },

  selection: ({ bebopApi, consoleError }) => {
    return bebopApi.getTopics({
      limit: 1,
      list: 'selection',
      sort: 'list',
      include: 'read_more,' + filterRequiredParams([CardSelection], 'include'),
      fields: filterRequiredParams([CardSelection, CardSelectionSimple], 'fields'),
    })
      .then(denormalizeData)
      .then(topics => {
        if (topics[0]) return topics[0];
        return null;
      })
      .catch(consoleError('selection list', []));
  },

  news: newsFetcher({ fromList: true }),
});

export default withPageHocs(dataProvider)(MainPage);
