import deepmerge from 'core/libs/deepmerge';
import color from 'core/libs/color';
import bindProps from 'core/components/bindProps';

import {
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT_MOBILE,
  CONTENT_AREA,
} from 'site/constants';

import Card from 'site/cards/Card3';

import fonts from './fonts';


const Card3Type2 = bindProps({ type: 2 })(Card);

export default function baseMobileTheme(atoms) {
  const texts = {
    lead: {
      font: `20px/29px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    body: {
      font: `20px/28px ${fonts.text}`,
    },

    title1: {
      font: `800 26px/34px ${fonts.display}`,
    },

    title2: {
      font: `700 24px/28px ${fonts.nuance}`,
    },

    title3: {
      font: `700 20px/26px ${fonts.nuance}`,
    },

    title4: {
      font: `700 20px/26px ${fonts.nuance}`,
    },

    title5: {
      font: `700 16px/22px ${fonts.nuance}`,
    },

    title6: {
      font: `700 20px/30px ${fonts.nuance}`,
    },

    caption1: {
      font: `14px/18px ${fonts.nuance}`,
    },

    caption3: {
      font: `14px/18px ${fonts.nuance}`,
    },

    quote: {
      font: `700 16px/22px ${fonts.display}`,
    },

    topicInfo: { // Info, Date, Rubric
      font: `700 12px/14px ${fonts.display}`,
    },

    imageDescription: {
      font: `12px/16px ${fonts.text}`,
    },

    imageCopyright: {
      font: `14px/22px ${fonts.nuance}`,
    },
  };

  const widgetCaption = {
    caption: {
      font: texts.imageCopyright.font,
    },
    credits: {
      font: texts.imageCopyright.font,
      color: atoms.colors.hint,
    },
  };

  const vikontReadMore = {
    block: {
      padding: '18px 0 18px 15px',
    },
    title: {
      letterSpacing: '0.08em',
      marginBottom: '16px',
    },
    drum: {
      itemWidth: '90%',
    },
  };

  const vikontImage = {
    maxWidth: CONTENT_AREA,
    maxHeight: 3000,
  };

  const scooter = {
    padding: `0 ${SIDE_INDENT_MOBILE}px`,
    menuLink: {
      font: `700 18px/40px ${fonts.nuance}`,
    },
    social: {
      margin: 0,
      size: 30,
      borderRadius: 3,
    },
  };

  const shapka = {
    padding: `0 ${SIDE_INDENT_MOBILE}px`,
    logo: {
      width: 40,
      height: 40,
    },
  };

  const expert = {
    name: {
      idle: {
        font: `600 14px/21px ${fonts.nuance}`,
      },
      hover: {
        font: `600 14px/21px ${fonts.nuance}`,
      },
    },
    jobTitle: {
      font: `400 12px/17px ${fonts.nuance}`,
    },
    label: {
      font: `700 8px/1 ${fonts.nuance}`,
    },
  };

  const errorPage = {
    card: Card3Type2,
  };

  const topicFooter = {
    commentButton: {
      topSpacing: VERTICAL_INDENT_MOBILE,
      size: 'medium',
    },
    block: {
      interitemSpacing: 10,
      spacing: VERTICAL_INDENT_MOBILE,
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 18px/22px ${fonts.nuance}`,
      },
      info: {
        font: `400 14px/26px ${fonts.nuance}`,
      },
    },
  };

  const commentsPreview = {
    headline: {
      font: `700 26px/1 ${fonts.nuance}`,
    },
    name: {
      font: `700 14px/18px ${fonts.nuance}`,
    },
  };

  const pages = {
    error: errorPage,
    notFound: errorPage,
    sentryFallback: {
      title: {
        font: `bold 20px/24px ${fonts.nuance}`,
      },
      subtitle: {
        font: `14px/18px ${fonts.nuance}`,
      },
    },
  };

  const layout = {
    indents: {
      top: VERTICAL_INDENT_MOBILE,
      right: SIDE_INDENT_MOBILE,
      bottom: VERTICAL_INDENT_MOBILE,
      left: SIDE_INDENT_MOBILE,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `800 22px/26px ${fonts.display}`,
    },
    subtitle: {
      font: `500 20px/26px ${fonts.display2}`,
    },
    divider: {
      margin: '20px calc(100% - 300px) 20px 0',
      background: color(atoms.colors.primary).alpha(0.15).string(),
    },
  };

  const gameCompare = {
    compare: {
      progressType: 3,
    },
    share: {
      showBorder: true,
      marginTop: '0px',
    },
    winner: {
      borderRadius: 0,
      viewBox: '0 0 870 1200',
      padding: '20px 15px',
    },
    leaderBoard: {
      caption: `300 13px/19px ${fonts.nuance}`,
      modalWindow: {
        color: atoms.colors.primary,
      },
    },
    tooltip: {
      scheme: {
        titleFont: `700 18px/30px ${fonts.nuance}`,
        textFont: `16px/23px ${fonts.nuance}`,
      },
    },
  };

  const reactionsEmojiBar = {
    title: {
      font: `700 18px/20px ${fonts.nuance}`,
    },
  };

  return deepmerge({
    controls: {
      vikontReadMore,
      scooter,
      shapka,
      expert,
      topicFooter,
      topicHeaderTexts,
      widgetCaption,
      commentsPreview,
      vikontImage,
      smartTeaser,
      gameCompare,
      reactionsEmojiBar,
    },
    pages,
    layout,
    texts,
  }, atoms);
}
