import PropTypes from 'prop-types';

import color from 'core/libs/color';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import Video from 'site/icons/Video';
import Photo from 'site/icons/Photo';

import mapTypes from './types';
import mapSizes from './sizes';

import styles from './index.styl';
import { getUtm } from 'site/constants';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'topic_type',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
});

function CardSelectionSimple(props) {
  const {
    content,
    theme,
    breakpoint,
    type,
    imageHeightShift,
  } = props;

  if (!content) return null;

  const {
    size,
  } = mapTypes[type] || {};

  const {
    headlineSize,
    altHeadlineSize,
    width: imageWidth,
    height: imageHeight,
  } = mapSizes[size] || {};

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      alternative_headline: altHeadline,
      topic_type: topicType,
    },
  } = content;

  const {
    image: {
      versions,
    },
  } = relationships(content);
  const utm = getUtm(breakpoint);
  const to = link + utm;

  return (
    <div className='cardSelectionSimple'>
      <style jsx>{`
        .cardSelectionSimple
          margin-top ${imageHeightShift ? `-${imageHeight * imageHeightShift}px` : 0 }

        .headline
          font 700 ${headlineSize} ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.altHeadline}
          font ${altHeadlineSize} ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .${styles.overlay}
          background ${color(theme.colors.content).alpha(0.3).toString()}
          transition opacity ${theme.animations.hover}
      `}</style>
      <Link
        to={to}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.image}>
          <Image
            versions={versions}
            aspectRatio={+(imageWidth / imageHeight).toFixed(2)}
          />
          <div className={styles.overlay} />
          {topicType === 'gallery' && <Photo className={styles.icon} />}
          {topicType === 'video' && <Video className={styles.icon} />}
        </div>
        <div className={styles.content}>
          <div className='headline'>{listHeadline || headline}</div>
          {altHeadline && <div className={styles.altHeadline}>{altHeadline}</div>}
        </div>
      </Link>
    </div>
  );
}

CardSelectionSimple.propTypes = {
  /** Можно задать смещение по высоте */
  imageHeightShift: PropTypes.number,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** @ignore */
  theme: PropTypes.object,
};

CardSelectionSimple.defaultProps = {
  type: 0,
  imageHeightShift: 0,
};

const Card = cardHOC(CardSelectionSimple);

Card.displayName = 'CardSelectionSimple';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardSelectionSimple as StorybookComponent };
