import color from 'core/libs/color';

const colors = {
  layout: '#F3F3F3',
  content: '#fff',
  card: '#fff',
  active1: '#CC442A',
  get active2() {
    return this.active1;
  },
  accent: '#efe5d4',
  error: '#ff3e56',
  primary: '#303030',
  input: '#fff',
  divider: '#EAEAEA',
  hint: '#ABABAB',
  placeholder: '#F3F3F3',
  black: '#000',
  papaya: '#fcf0e4',
  papayaDark: '#78473d',
  logo: '#CC442A',
  get galleryIconsBackground() {
    return color(this.primary).alpha(0.5).string();
  },
  get theme() {
    return this.active1;
  },
};

const button = {
  primary: {
    idle: {
      color: colors.content,
      background: colors.active1,
      border: 'none',
    },

    hover: {
      color: colors.content,
      background: color(colors.active1).mix(color(colors.primary), 0.1).string(),
      border: 'none',
    },

    active: {
      color: colors.content,
      background: colors.active1,
      border: 'none',
    },

    disabled: {
      color: colors.content,
      background: `${color(colors.active2).alpha(0.1).string()}`,
      border: 'none',
    },
  },
};

const link = {
  tertiary: {
    idle: {
      decoration: 'none',
    },

    visited: {
      color: colors.primary,
    },
  },
};

export default {
  colors,
  controls: {
    button,
    link,
  },
};
