import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import { TopicContentMaxWidth } from 'core/components/Wrappers';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import mapSizes from 'site/cards/Card3/sizes';
import mapTypes from 'site/cards/Card3/types';


import './index.styl';

const CARD_TYPE = 7;

const Card3Type7Styles = ({ theme }) => {
  const {
    block: {
      background,
      padding,
    },
  } = theme.controls.vikontReadMore;

  const { size: cardSize } = mapTypes[CARD_TYPE];

  const {
    height: imageHeight,
    width: imageWidth,
    headlineSize,
    altHeadlineSize,
  } = mapSizes[cardSize];

  return (
    <Fragment>
      <style jsx global>{`
        .native-ad-3
          .ad_native_wrapper
            padding ${padding}
            background ${background}

          .ad_native_content-wrapper
            background-color ${theme.colors.content}

          .ad_label__text
            font 16px/22px ${theme.fonts.nuance}
            color ${theme.colors.hint}
  
          .adv_info__text
            font 14px/1.3 ${theme.fonts.nuance}

          .ad_native_img
            height ${imageHeight}px
            flex 0 0 ${imageWidth}px

          .ad_native_title
            color ${theme.colors.primary}
            font ${headlineSize} ${theme.fonts.nuance}

          .ad_native_desc
            color ${theme.colors.primary}
            font ${altHeadlineSize} ${theme.fonts.nuance}
      `}</style>
      <style jsx global>{`
        .native-ad-3
          .ad_native_img
            img
              max-width none
      `}</style>
    </Fragment>
  );
};

Card3Type7Styles.propTypes = {
  theme: PropTypes.object,
  breakpoint: breakpointPropTypes(),
};

export default function card3Type7(Component) {
  return withTheme(withBreakpoint(props => (
    <TopicContentMaxWidth>
      <div className='native-ad native-ad-3'>
        <Card3Type7Styles {...props} />
        <Component {...props} />
      </div>
    </TopicContentMaxWidth>
  )));
}
