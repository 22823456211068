import { Fragment } from 'react';
import PropTypes from 'prop-types';

import SearchPage from 'core/components/SearchPage';
import withPageHocs from 'core/components/withPageHocs';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import FooterAds from 'site/components/Ads/FooterAds';
import { SideIndent } from 'core/components/Wrappers';
import PageLayout from 'site/components/PageLayout';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  TopBanner,
  Listing1,
  ListingSpecSearch,
} from 'site/components/Ads/mobile';

import CardNews from 'site/cards/CardNews';

import filters from './filters';

/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
function Search({ rawContent }) {
  return (
    <Fragment>
      <PageLayout showBouesque={false}>
        <SearchPage
          rawContent={rawContent}
          filters={filters}
          topBanner={TopBanner}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpecSearch}
          card={CardNews}
          dataQa='search-page'
        />
      </PageLayout>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

Search.propTypes = {
  rawContent: PropTypes.array,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: CardNews }),
});

export default withPageHocs(dataProvider)(Search);
