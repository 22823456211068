import PropTypes from 'prop-types';

import GalleryWithAds from 'site/components/GalleryWithAds';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';

import EmptyWrapper from 'core/components/EmptyWrapper';
import { TopicContentMaxWidth } from 'core/components/Wrappers';

export default function PhotoGallery({ block, parentProps }) {
  const {
    topicType,
  } = parentProps;

  const isCard = topicType === 'card';

  const Wrapper = isCard ? TopicContentMaxWidth : EmptyWrapper;

  return (
    <Wrapper>
      <GalleryWithAds photoGallery={block} />
    </Wrapper>
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
  parentProps: PropTypes.object,
};
