import { Fragment } from 'react';
import PropTypes from 'prop-types';

import StickyPortal from 'core/components/StickyPortal';
import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';
import SelfPromo from 'core/components/SelfPromo';

import Popular from 'site/components/Popular';
import { Indent } from 'core/components/Wrappers';
import { PartnersVertical } from 'site/components/Partners';

import {
  Ad240x400,
  Ad240x200,
  Ad240x400Second,
} from 'site/components/Ads/desktop';

import { VERTICAL_INDENT } from 'site/constants';

export default function InnerSideColumn(props) {
  const {
    show240x200,
    shortContent,
    showBouesque,
    infinityIndex,
  } = props;

  return (
    <Fragment>
      {showBouesque && (
        <Indent bottom={VERTICAL_INDENT}>
          <Bouesque />
        </Indent>
      )}
      <StickyPortal listenStickyRemoveEvent>
        <AdWrapper bottom={VERTICAL_INDENT}>
          <Ad240x400 />
          <SelfPromo />
          <Indent bottom={VERTICAL_INDENT}>
            <PartnersVertical />
          </Indent>
          {show240x200 && <Ad240x200 />}
        </AdWrapper>
      </StickyPortal>
      <StickyPortal>
        {shortContent ? (
          <Ad240x400Second />
        ) : (
          <AdWrapper bottom={VERTICAL_INDENT}>
            <Ad240x400Second />
            {!infinityIndex && <Popular />}
          </AdWrapper>
        )}
      </StickyPortal>
    </Fragment>
  );
}

InnerSideColumn.defaultProps = {
  showBouesque: true,
};

InnerSideColumn.propTypes = {
  /**
   * Укорачиваем содержимое сайдбара, если длина топика небольшая (например Новость)
   */
  shortContent: PropTypes.bool,
  showBouesque: PropTypes.bool,
  infinityIndex: PropTypes.number,
  show240x200: PropTypes.bool,
};
